import '@/unregisterServiceWorker';
import '@/utils/initDevtools';
import '@stripe/stripe-js';
import 'buefy/dist/buefy.css';
import 'focus-visible';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import '@/style/index.sass';
import {
  getRouter,
  getStore,
  initBuefy,
  initFirebase,
  initSegment,
  readyForVue,
} from '@caresend/ui-components';
import {
  faComment,
  faFlag,
  faLink,
  faMobile,
  faPhoneAlt,
  faQuestion,
  faVideo,
} from '@fortawesome/sharp-regular-svg-icons';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { PiniaVuePlugin, createPinia } from 'pinia';
import Vue from 'vue';
import VueMobileDetection from 'vue-mobile-detection';

import App from '@/App.vue';
import ImagePane from '@/components/layout/image/ImagePane.vue';
import FormHeader from '@/components/layout/text/FormHeader.vue';
import PageHeader from '@/components/layout/text/PageHeader.vue';
import { SEGMENT_TOKEN } from '@/database/credentials';
import { initializeSentry } from '@/functions/tracking';
import { initRouter } from '@/router';
import { initStore } from '@/store';

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(utc);
dayjs.extend(timezone);

initFirebase();
initSegment(SEGMENT_TOKEN);
initializeSentry();
initStore();

// TODO: Remove all global components.
Vue.component('ImagePane', ImagePane);
Vue.component('PageHeader', PageHeader);
Vue.component('FormHeader', FormHeader);

// TODO: Remove when all $isMobile removed
Vue.use(VueMobileDetection);

const faSharpRegularAdditionalIcons = [
  faComment,
  faFlag,
  faLink,
  faMobile,
  faPhoneAlt,
  faQuestion,
  faVideo,
];
initBuefy(Vue, faSharpRegularAdditionalIcons);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.config.productionTip = false;

const initApp = async () => {
  await getStore().dispatch('app/initApp');

  initRouter();

  await readyForVue();

  new Vue({
    name: 'Patient',
    router: getRouter(),
    store: getStore(),
    pinia,
    render: (h) => h(App),
  }).$mount('#app');
};

initApp();
