import { EncryptedUser } from '@caresend/types';
import { analytics, initSentry, isProd } from '@caresend/ui-components';
import {
  formatFullName,
  formatPhoneNumber,
  getCityByLocation,
  getCountryByLocation,
  getPostalCodeByLocation,
  getStateByLocation,
  getStreetNameByLocation,
  isNullish,
} from '@caresend/utils';
import { UserTraits } from '@segment/analytics-next';
import isEqual from 'lodash.isequal';
import Vue from 'vue';

import { isLocal } from '@/data/urls';
import { SENTRY_DSN } from '@/database/credentials';
import { formatDateOfBirth } from '@/functions/date';

export const initializeSentry = () => {
  initSentry(isProd, {
    Vue,
    dsn: !isLocal ? SENTRY_DSN : undefined,
    release: `${process.env.VITE_NAME}@${process.env.VITE_VERSION}@${process.env.VITE_CI_COMMIT_SHORT_SHA}`,
  });
};

/**
 * Segment
 */
export const trackPageViewSegment = () => {
  analytics.page();
};

export const identifyAnonymousUserSegment = () => {
  analytics.identify();
};

export const getSegmentIdentityFromState = (user: EncryptedUser): UserTraits => {
  const { info, role, status } = user;
  const { email, phone, address, gender, dateOfBirth, languages } = info;
  const name = formatFullName(user);
  return {
    email,
    name,
    role,
    address: {
      city: getCityByLocation(address),
      country: getCountryByLocation(address),
      postalCode: getPostalCodeByLocation(address),
      state: getStateByLocation(address),
      street: getStreetNameByLocation(address),
    },
    phone: formatPhoneNumber(phone),
    gender,
    dateOfBirth: formatDateOfBirth(dateOfBirth),
    languagesName: Object.values(languages ?? {}).map((language) => language.language),
    languagesStatus: Object.values(languages ?? {}).map((language) => language.fluency),
    status,
  };
};

export const identifyAuthenticatedUserSegment = (user: EncryptedUser) => {
  const segmentIdentity = getSegmentIdentityFromState(user);
  analytics.identify(user.id, segmentIdentity);
};

/**
 * We should only call segment `identify` for a user that has already been
 * identified during this session if the user details that we report in the
 * `identify` call have changed.
 */
export const maybeIdentifyUserAgainSegment = (
  user: EncryptedUser,
  prevUser: EncryptedUser,
) => {
  const previousSegmentIdentity = !isNullish(prevUser)
    ? getSegmentIdentityFromState(prevUser)
    : null;

  const newSegmentIdentity = getSegmentIdentityFromState(user);
  const hasSegmentIdentityUpdated = !isEqual(
    previousSegmentIdentity,
    newSegmentIdentity,
  );

  if (hasSegmentIdentityUpdated) {
    identifyAuthenticatedUserSegment(user);
  }
};
